// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { jsonObjectGuard, ssoDomainsGuard, ssoBrandingGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(128),
    providerName: z.string().min(1).max(128),
    connectorName: z.string().min(1).max(128),
    config: jsonObjectGuard.optional(),
    domains: ssoDomainsGuard.optional(),
    branding: ssoBrandingGuard.optional(),
    syncProfile: z.boolean().optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(128),
    providerName: z.string().min(1).max(128),
    connectorName: z.string().min(1).max(128),
    config: jsonObjectGuard,
    domains: ssoDomainsGuard,
    branding: ssoBrandingGuard,
    syncProfile: z.boolean(),
    createdAt: z.number(),
});
export const SsoConnectors = Object.freeze({
    table: 'sso_connectors',
    tableSingular: 'sso_connector',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        providerName: 'provider_name',
        connectorName: 'connector_name',
        config: 'config',
        domains: 'domains',
        branding: 'branding',
        syncProfile: 'sync_profile',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'providerName',
        'connectorName',
        'config',
        'domains',
        'branding',
        'syncProfile',
        'createdAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
