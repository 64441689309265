// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { OrganizationInvitationStatus } from './custom-types.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    inviterId: z.string().max(21).nullable().optional(),
    invitee: z.string().min(1).max(256),
    acceptedUserId: z.string().max(21).nullable().optional(),
    organizationId: z.string().min(1).max(21),
    status: z.nativeEnum(OrganizationInvitationStatus),
    createdAt: z.number().optional(),
    updatedAt: z.number().optional(),
    expiresAt: z.number(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    inviterId: z.string().max(21).nullable(),
    invitee: z.string().min(1).max(256),
    acceptedUserId: z.string().max(21).nullable(),
    organizationId: z.string().min(1).max(21),
    status: z.nativeEnum(OrganizationInvitationStatus),
    createdAt: z.number(),
    updatedAt: z.number(),
    expiresAt: z.number(),
});
export const OrganizationInvitations = Object.freeze({
    table: 'organization_invitations',
    tableSingular: 'organization_invitation',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        inviterId: 'inviter_id',
        invitee: 'invitee',
        acceptedUserId: 'accepted_user_id',
        organizationId: 'organization_id',
        status: 'status',
        createdAt: 'created_at',
        updatedAt: 'updated_at',
        expiresAt: 'expires_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'inviterId',
        'invitee',
        'acceptedUserId',
        'organizationId',
        'status',
        'createdAt',
        'updatedAt',
        'expiresAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
