import { z } from 'zod';
// Ref: `prompt` in https://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
export var OidcPrompt;
(function (OidcPrompt) {
    OidcPrompt["None"] = "none";
    OidcPrompt["Login"] = "login";
    OidcPrompt["Consent"] = "consent";
    OidcPrompt["SelectAccount"] = "select_account";
})(OidcPrompt || (OidcPrompt = {}));
export const oidcPromptsGuard = z
    .nativeEnum(OidcPrompt)
    .array()
    .optional();
// Copied from https://github.com/colinhacks/zod#json-type
const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
export const jsonGuard = z.lazy(() => z.union([literalSchema, z.array(jsonGuard), z.record(jsonGuard)]));
export const jsonObjectGuard = z.record(jsonGuard);
export const socialUserInfoGuard = z.object({
    id: z.string(),
    email: z.string().optional(),
    phone: z.string().optional(),
    name: z.string().optional(),
    avatar: z.string().optional(),
    rawData: jsonGuard.optional(),
});
export const connectorSessionGuard = z
    .object({
    nonce: z.string(),
    redirectUri: z.string(),
    connectorId: z.string(),
    connectorFactoryId: z.string(),
    jti: z.string(),
    state: z.string(),
})
    .partial()
    // Accept arbitrary unspecified keys so developers who can not publish @logto/connector-kit can more flexibly utilize connector session.
    .catchall(z.unknown());
export const googleOneTapConfigGuard = z.object({
    isEnabled: z.boolean().optional(),
    autoSelect: z.boolean().optional(),
    closeOnTapOutside: z.boolean().optional(),
    itpSupport: z.boolean().optional(),
});
/**
 * An object that contains the configuration for the official Google connector.
 *
 * @remarks
 * Unlike other connectors, the Google connector supports Google One Tap which needs additional
 * configuration and special handling in our system. So we put the constants and configuration
 * in this package for reusability, rather than hardcoding them in our system.
 *
 * Other connectors should not follow this pattern unless there is a strong reason to do so.
 */
export const GoogleConnector = Object.freeze({
    /** The target of Google connectors. */
    target: 'google',
    /** The factory ID of the official Google connector. */
    factoryId: 'google-universal',
    oneTapParams: Object.freeze({
        /** The parameter Google One Tap uses to prevent CSRF attacks. */
        csrfToken: 'g_csrf_token',
        /** The parameter Google One Tap uses to carry the ID token. */
        credential: 'credential',
    }),
    configGuard: z.object({
        clientId: z.string(),
        clientSecret: z.string(),
        scope: z.string().optional(),
        prompts: oidcPromptsGuard,
        oneTap: googleOneTapConfigGuard.optional(),
    }),
});
