import { z } from 'zod';
import { Users, UserSsoIdentities } from '../db-entries/index.js';
import { MfaFactor } from '../foundations/index.js';
export const userInfoSelectFields = Object.freeze([
    'id',
    'username',
    'primaryEmail',
    'primaryPhone',
    'name',
    'avatar',
    'customData',
    'identities',
    'lastSignInAt',
    'createdAt',
    'updatedAt',
    'profile',
    'applicationId',
    'isSuspended',
]);
export const userInfoGuard = Users.guard.pick(
// eslint-disable-next-line no-restricted-syntax
Object.fromEntries(userInfoSelectFields.map((field) => [field, true])));
export const userProfileResponseGuard = userInfoGuard.extend({
    hasPassword: z.boolean().optional(),
    ssoIdentities: z.array(UserSsoIdentities.guard).optional(),
});
export const userMfaVerificationResponseGuard = z
    .object({
    id: z.string(),
    createdAt: z.string(),
    type: z.nativeEnum(MfaFactor),
    agent: z.string().optional(),
    remainCodes: z.number().optional(),
})
    .array();
/**
 * Internal read-only roles for user tenants.
 *
 * @deprecated We don't use internal roles anymore.
 */
export var InternalRole;
(function (InternalRole) {
    /**
     * Internal admin role for Machine-to-Machine apps in Logto user tenants.
     *
     * It should NOT be assigned to any user.
     */
    InternalRole["Admin"] = "#internal:admin";
})(InternalRole || (InternalRole = {}));
export var AdminTenantRole;
(function (AdminTenantRole) {
    /** Common user role in admin tenant. */
    AdminTenantRole["User"] = "user";
    /** The role for machine to machine applications that represent a user tenant and send requests to Logto Cloud. */
    AdminTenantRole["TenantApplication"] = "tenantApplication";
})(AdminTenantRole || (AdminTenantRole = {}));
export var PredefinedScope;
(function (PredefinedScope) {
    PredefinedScope["All"] = "all";
})(PredefinedScope || (PredefinedScope = {}));
/** The guard for {@link FeaturedUser}. */
export const featuredUserGuard = Users.guard.pick({
    id: true,
    avatar: true,
    name: true,
});
