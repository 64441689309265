// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    organizationId: z.string().min(1).max(21),
    organizationRoleId: z.string().min(1).max(21),
    applicationId: z.string().min(1).max(21),
});
const guard = z.object({
    tenantId: z.string().max(21),
    organizationId: z.string().min(1).max(21),
    organizationRoleId: z.string().min(1).max(21),
    applicationId: z.string().min(1).max(21),
});
export const OrganizationRoleApplicationRelations = Object.freeze({
    table: 'organization_role_application_relations',
    tableSingular: 'organization_role_application_relation',
    fields: {
        tenantId: 'tenant_id',
        organizationId: 'organization_id',
        organizationRoleId: 'organization_role_id',
        applicationId: 'application_id',
    },
    fieldKeys: [
        'tenantId',
        'organizationId',
        'organizationRoleId',
        'applicationId',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
