// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    organizationRoleId: z.string().min(1).max(21),
    organizationScopeId: z.string().min(1).max(21),
});
const guard = z.object({
    tenantId: z.string().max(21),
    organizationRoleId: z.string().min(1).max(21),
    organizationScopeId: z.string().min(1).max(21),
});
export const OrganizationRoleScopeRelations = Object.freeze({
    table: 'organization_role_scope_relations',
    tableSingular: 'organization_role_scope_relation',
    fields: {
        tenantId: 'tenant_id',
        organizationRoleId: 'organization_role_id',
        organizationScopeId: 'organization_scope_id',
    },
    fieldKeys: [
        'tenantId',
        'organizationRoleId',
        'organizationScopeId',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
