// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    applicationId: z.string().min(1).max(21),
    organizationScopeId: z.string().min(1).max(21),
});
const guard = z.object({
    tenantId: z.string().max(21),
    applicationId: z.string().min(1).max(21),
    organizationScopeId: z.string().min(1).max(21),
});
export const ApplicationUserConsentOrganizationScopes = Object.freeze({
    table: 'application_user_consent_organization_scopes',
    tableSingular: 'application_user_consent_organization_scope',
    fields: {
        tenantId: 'tenant_id',
        applicationId: 'application_id',
        organizationScopeId: 'organization_scope_id',
    },
    fieldKeys: [
        'tenantId',
        'applicationId',
        'organizationScopeId',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
