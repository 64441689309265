// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { jsonObjectGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    userId: z.string().max(21).nullable().optional(),
    createdAt: z.number().optional(),
    expiresAt: z.number(),
    data: jsonObjectGuard.optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    userId: z.string().max(21).nullable(),
    createdAt: z.number(),
    expiresAt: z.number(),
    data: jsonObjectGuard,
});
export const VerificationRecords = Object.freeze({
    table: 'verification_records',
    tableSingular: 'verification_record',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        userId: 'user_id',
        createdAt: 'created_at',
        expiresAt: 'expires_at',
        data: 'data',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'userId',
        'createdAt',
        'expiresAt',
        'data',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
