// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    organizationId: z.string().min(1).max(21),
    ssoConnectorId: z.string().min(1).max(128),
});
const guard = z.object({
    tenantId: z.string().max(21),
    organizationId: z.string().min(1).max(21),
    ssoConnectorId: z.string().min(1).max(128),
});
export const OrganizationJitSsoConnectors = Object.freeze({
    table: 'organization_jit_sso_connectors',
    tableSingular: 'organization_jit_sso_connector',
    fields: {
        tenantId: 'tenant_id',
        organizationId: 'organization_id',
        ssoConnectorId: 'sso_connector_id',
    },
    fieldKeys: [
        'tenantId',
        'organizationId',
        'ssoConnectorId',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
