import { z } from 'zod';
export const userOnboardingDataKey = 'onboarding';
export var Project;
(function (Project) {
    Project["Personal"] = "personal";
    Project["Company"] = "company";
})(Project || (Project = {}));
/** @deprecated Open-source options was for cloud preview use, no longer needed. Use default `Cloud` value for placeholder. */
var DeploymentType;
(function (DeploymentType) {
    DeploymentType["OpenSource"] = "open-source";
    DeploymentType["Cloud"] = "cloud";
})(DeploymentType || (DeploymentType = {}));
/** @deprecated */
export var Title;
(function (Title) {
    Title["Developer"] = "developer";
    Title["TeamLead"] = "team-lead";
    Title["Ceo"] = "ceo";
    Title["Cto"] = "cto";
    Title["Product"] = "product";
    Title["Others"] = "others";
})(Title || (Title = {}));
/** @deprecated */
export var CompanySize;
(function (CompanySize) {
    CompanySize["Scale1"] = "1";
    CompanySize["Scale2"] = "2-49";
    CompanySize["Scale3"] = "50-199";
    CompanySize["Scale4"] = "200-999";
    CompanySize["Scale5"] = "1000+";
})(CompanySize || (CompanySize = {}));
/** @deprecated */
export var Reason;
(function (Reason) {
    Reason["Passwordless"] = "passwordless";
    Reason["Efficiency"] = "efficiency";
    Reason["AccessControl"] = "access-control";
    Reason["MultiTenancy"] = "multi-tenancy";
    Reason["Enterprise"] = "enterprise";
    Reason["Others"] = "others";
})(Reason || (Reason = {}));
export var Stage;
(function (Stage) {
    Stage["NewProduct"] = "new-product";
    Stage["ExistingProduct"] = "existing-product";
    Stage["TargetEnterpriseReady"] = "target-enterprise-ready";
})(Stage || (Stage = {}));
export var AdditionalFeatures;
(function (AdditionalFeatures) {
    AdditionalFeatures["CustomizeUiAndFlow"] = "customize-ui-and-flow";
    AdditionalFeatures["Compliance"] = "compliance";
    AdditionalFeatures["ExportUserDataFromLogto"] = "export-user-data-from-logto";
    AdditionalFeatures["BudgetControl"] = "budget-control";
    AdditionalFeatures["BringOwnAuth"] = "bring-own-auth";
    AdditionalFeatures["Others"] = "others";
})(AdditionalFeatures || (AdditionalFeatures = {}));
const questionnaireGuard = z.object({
    project: z.nativeEnum(Project).optional(),
    /** @deprecated Open-source options was for cloud preview use, no longer needed. Use default `Cloud` value for placeholder. */
    deploymentType: z.nativeEnum(DeploymentType).optional().default(DeploymentType.Cloud),
    /** @deprecated */
    titles: z.array(z.nativeEnum(Title)).optional(),
    companyName: z.string().optional(),
    /** @deprecated */
    companySize: z.nativeEnum(CompanySize).optional(),
    /** @deprecated */
    reasons: z.array(z.nativeEnum(Reason)).optional(),
    stage: z.nativeEnum(Stage).optional(),
    additionalFeatures: z.array(z.nativeEnum(AdditionalFeatures)).optional(),
});
export const userOnboardingDataGuard = z.object({
    questionnaire: questionnaireGuard.optional(),
    isOnboardingDone: z.boolean().optional(),
});
