// MARK: SMS + Email connector
import { z } from 'zod';
/** @deprecated Use {@link TemplateType} instead. */
export var VerificationCodeType;
(function (VerificationCodeType) {
    VerificationCodeType["SignIn"] = "SignIn";
    VerificationCodeType["Register"] = "Register";
    VerificationCodeType["ForgotPassword"] = "ForgotPassword";
    VerificationCodeType["Generic"] = "Generic";
    /** @deprecated Use `Generic` type template for sending test sms/email use case */
    VerificationCodeType["Test"] = "Test";
})(VerificationCodeType || (VerificationCodeType = {}));
/** @deprecated Use {@link templateTypeGuard} instead. */
export const verificationCodeTypeGuard = z.nativeEnum(VerificationCodeType);
export var TemplateType;
(function (TemplateType) {
    /** The template for sending verification code when user is signing in. */
    TemplateType["SignIn"] = "SignIn";
    /** The template for sending verification code when user is registering. */
    TemplateType["Register"] = "Register";
    /** The template for sending verification code when user is resetting password. */
    TemplateType["ForgotPassword"] = "ForgotPassword";
    /** The template for sending organization invitation. */
    TemplateType["OrganizationInvitation"] = "OrganizationInvitation";
    /** The template for generic usage. */
    TemplateType["Generic"] = "Generic";
})(TemplateType || (TemplateType = {}));
export const templateTypeGuard = z.nativeEnum(TemplateType);
/** The guard for {@link SendMessagePayload}. */
export const sendMessagePayloadGuard = z
    .object({
    code: z.string().optional(),
    link: z.string().optional(),
})
    .and(z.record(z.string()));
export const urlRegEx = /(https?:\/\/)?(?:www\.)?[\w#%+.:=@~-]{1,256}\.[\d()A-Za-z]{1,6}\b[\w#%&()+./:=?@~-]*/;
export const emailServiceBrandingGuard = z
    .object({
    senderName: z
        .string()
        .refine((address) => !urlRegEx.test(address), 'URL is not allowed in sender name.'),
    companyInformation: z
        .string()
        .refine((address) => !urlRegEx.test(address), 'URL is not allowed in company information.'),
    appLogo: z.string().url(),
})
    .partial();
export const sendMessageDataGuard = z.object({
    to: z.string(),
    type: templateTypeGuard.or(verificationCodeTypeGuard),
    payload: sendMessagePayloadGuard,
});
