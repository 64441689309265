// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    userId: z.string().min(1).max(21),
    name: z.string().min(1).max(256),
    value: z.string().min(1).max(64),
    createdAt: z.number().optional(),
    expiresAt: z.number().nullable().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    userId: z.string().min(1).max(21),
    name: z.string().min(1).max(256),
    value: z.string().min(1).max(64),
    createdAt: z.number(),
    expiresAt: z.number().nullable(),
});
export const PersonalAccessTokens = Object.freeze({
    table: 'personal_access_tokens',
    tableSingular: 'personal_access_token',
    fields: {
        tenantId: 'tenant_id',
        userId: 'user_id',
        name: 'name',
        value: 'value',
        createdAt: 'created_at',
        expiresAt: 'expires_at',
    },
    fieldKeys: [
        'tenantId',
        'userId',
        'name',
        'value',
        'createdAt',
        'expiresAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
