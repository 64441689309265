// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    applicationId: z.string().min(1).max(21),
    userScope: z.string().min(1).max(64),
});
const guard = z.object({
    tenantId: z.string().max(21),
    applicationId: z.string().min(1).max(21),
    userScope: z.string().min(1).max(64),
});
export const ApplicationUserConsentUserScopes = Object.freeze({
    table: 'application_user_consent_user_scopes',
    tableSingular: 'application_user_consent_user_scope',
    fields: {
        tenantId: 'tenant_id',
        applicationId: 'application_id',
        userScope: 'user_scope',
    },
    fieldKeys: [
        'tenantId',
        'applicationId',
        'userScope',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
