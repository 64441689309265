// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { RoleType } from './custom-types.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    name: z.string().min(1).max(128),
    description: z.string().min(1).max(128),
    type: z.nativeEnum(RoleType).optional(),
    isDefault: z.boolean().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    name: z.string().min(1).max(128),
    description: z.string().min(1).max(128),
    type: z.nativeEnum(RoleType),
    isDefault: z.boolean(),
});
export const Roles = Object.freeze({
    table: 'roles',
    tableSingular: 'role',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        name: 'name',
        description: 'description',
        type: 'type',
        isDefault: 'is_default',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'name',
        'description',
        'type',
        'isDefault',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
