import { z } from 'zod';
export const domainDnsRecordGuard = z.object({
    name: z.string(),
    type: z.string(),
    value: z.string(),
});
export const domainDnsRecordsGuard = domainDnsRecordGuard.array();
// https://developers.cloudflare.com/api/operations/custom-hostname-for-a-zone-list-custom-hostnames#Responses
// Predefine the "useful" fields
export const cloudflareDataGuard = z.object({
    id: z.string(),
    status: z.string(),
    ssl: z.object({
        status: z.string(),
        validation_errors: z
            .object({
            message: z.string(),
        })
            .array()
            .optional(),
    }),
    verification_errors: z.string().array().optional(),
});
export var DomainStatus;
(function (DomainStatus) {
    DomainStatus["PendingVerification"] = "PendingVerification";
    DomainStatus["PendingSsl"] = "PendingSsl";
    DomainStatus["Active"] = "Active";
    DomainStatus["Error"] = "Error";
})(DomainStatus || (DomainStatus = {}));
export const domainStatusGuard = z.nativeEnum(DomainStatus);
