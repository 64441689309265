import { z } from 'zod';
/** The action target type of a sentinel activity. */
export var SentinelActivityTargetType;
(function (SentinelActivityTargetType) {
    SentinelActivityTargetType["User"] = "User";
    SentinelActivityTargetType["App"] = "App";
})(SentinelActivityTargetType || (SentinelActivityTargetType = {}));
export const sentinelActivityTargetTypeGuard = z.nativeEnum(SentinelActivityTargetType);
/** The action type of a sentinel activity. */
export var SentinelActivityAction;
(function (SentinelActivityAction) {
    /**
     * The subject tries to pass a verification by inputting a password.
     *
     * For example, a user (subject) who inputted a password (action) to authenticate themselves
     * (target).
     */
    SentinelActivityAction["Password"] = "Password";
    /**
     * The subject tries to pass a verification by inputting a verification code.
     *
     * For example, a user (subject) who inputted a verification code (action) to authenticate
     * themselves (target).
     */
    SentinelActivityAction["VerificationCode"] = "VerificationCode";
})(SentinelActivityAction || (SentinelActivityAction = {}));
export const sentinelActivityActionGuard = z.nativeEnum(SentinelActivityAction);
export const sentinelActivityPayloadGuard = z.record(z.unknown());
