// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    id: z.string().min(1).max(21),
    tenantId: z.string().max(21).optional(),
    usage: z.number().optional(),
    date: z.number(),
});
const guard = z.object({
    id: z.string().min(1).max(21),
    tenantId: z.string().max(21),
    usage: z.number(),
    date: z.number(),
});
export const DailyTokenUsage = Object.freeze({
    table: 'daily_token_usage',
    tableSingular: 'daily_token_usage',
    fields: {
        id: 'id',
        tenantId: 'tenant_id',
        usage: 'usage',
        date: 'date',
    },
    fieldKeys: [
        'id',
        'tenantId',
        'usage',
        'date',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
