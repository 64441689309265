// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { jsonObjectGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(25),
    context: jsonObjectGuard.optional(),
    expiresAt: z.number(),
    consumedAt: z.number().nullable().optional(),
    userId: z.string().min(1).max(21),
    createdAt: z.number().optional(),
    creatorId: z.string().min(1).max(32),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(25),
    context: jsonObjectGuard,
    expiresAt: z.number(),
    consumedAt: z.number().nullable(),
    userId: z.string().min(1).max(21),
    createdAt: z.number(),
    creatorId: z.string().min(1).max(32),
});
export const SubjectTokens = Object.freeze({
    table: 'subject_tokens',
    tableSingular: 'subject_token',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        context: 'context',
        expiresAt: 'expires_at',
        consumedAt: 'consumed_at',
        userId: 'user_id',
        createdAt: 'created_at',
        creatorId: 'creator_id',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'context',
        'expiresAt',
        'consumedAt',
        'userId',
        'createdAt',
        'creatorId',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
