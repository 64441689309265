export const languages = Object.freeze({
    'af-ZA': 'Afrikaans',
    'am-ET': 'አማርኛ',
    ar: 'العربية',
    /** @deprecated Use 'ar' instead. */
    'ar-AR': 'العربية (مهجورة)',
    'as-IN': 'অসমীয়া',
    'az-AZ': 'Azərbaycan dili',
    'be-BY': 'Беларуская',
    'bg-BG': 'Български',
    'bn-IN': 'বাংলা',
    'br-FR': 'Brezhoneg',
    'bs-BA': 'Bosanski',
    'ca-ES': 'Català',
    'cb-IQ': 'کوردیی ناوەندی',
    'co-FR': 'Corsu',
    'cs-CZ': 'Čeština',
    'cx-PH': 'Bisaya',
    'cy-GB': 'Cymraeg',
    'da-DK': 'Dansk',
    de: 'Deutsch',
    'de-DE': 'Deutsch (Deutschland)',
    'el-GR': 'Ελληνικά',
    en: 'English',
    'en-GB': 'English (UK)',
    'en-US': 'English (US)',
    'eo-EO': 'Esperanto',
    es: 'Español',
    'es-ES': 'Español (España)',
    'es-419': 'Español (Latinoamérica)',
    'et-EE': 'Eesti',
    'eu-ES': 'Euskara',
    'fa-IR': 'فارسی',
    'ff-NG': 'Fula',
    'fi-FI': 'Suomi',
    'fo-FO': 'Føroyskt',
    fr: 'Français',
    'fr-CA': 'Français (Canada)',
    'fr-FR': 'Français (France)',
    'fy-NL': 'Frysk',
    'ga-IE': 'Gaeilge',
    'gl-ES': 'Galego',
    'gn-PY': 'Guarani',
    'gu-IN': 'ગુજરાતી',
    'ha-NG': 'Hausa',
    'he-IL': 'עברית',
    'hi-IN': 'हिन्दी',
    'hr-HR': 'Hrvatski',
    'ht-HT': 'Kreyòl Ayisyen',
    'hu-HU': 'Magyar',
    'hy-AM': 'Հայերեն',
    'id-ID': 'Bahasa Indonesia',
    'ik-US': 'Iñupiatun',
    'is-IS': 'Íslenska',
    it: 'Italiano',
    'it-IT': 'Italiano (Italia)',
    'iu-CA': 'Inuktitut',
    ja: '日本語',
    'ja-JP': '日本語',
    'ja-KS': '日本語(関西)',
    'jv-ID': 'Basa Jawa',
    'ka-GE': 'ქართული',
    'kk-KZ': 'Қазақша',
    'km-KH': 'ភាសាខ្មែរ',
    'kn-IN': 'ಕನ್ನಡ',
    ko: '한국어',
    'ko-KR': '한국어',
    'ku-TR': 'Kurdî (Kurmancî)',
    'ky-KG': 'кыргызча',
    'lo-LA': 'ພາສາລາວ',
    'lt-LT': 'Lietuvių',
    'lv-LV': 'Latviešu',
    'mg-MG': 'Malagasy',
    'mk-MK': 'Македонски',
    'ml-IN': 'മലയാളം',
    'mn-MN': 'Монгол',
    'mr-IN': 'मराठी',
    'ms-MY': 'Bahasa Melayu',
    'mt-MT': 'Malti',
    'my-MM': 'မြန်မာဘာသာ',
    'nb-NO': 'Norsk (bokmål)',
    'ne-NP': 'नेपाली',
    'nl-BE': 'Vlaams',
    'nl-NL': 'Nederlands',
    'nn-NO': 'Norsk (nynorsk)',
    'or-IN': 'ଓଡ଼ିଆ',
    'pa-IN': 'ਪੰਜਾਬੀ',
    'pl-PL': 'Polski',
    'ps-AF': 'پښتو',
    pt: 'português',
    'pt-BR': 'Português (Brasil)',
    'pt-PT': 'Português (Portugal)',
    'ro-RO': 'Română',
    ru: 'Русский',
    'ru-RU': 'Русский',
    'rw-RW': 'Ikinyarwanda',
    'sc-IT': 'Sardu',
    'si-LK': 'සිංහල',
    'sk-SK': 'Slovenčina',
    'sl-SI': 'Slovenščina',
    'sn-ZW': 'Shona',
    'sq-AL': 'Shqip',
    'sr-RS': 'Српски',
    'sv-SE': 'Svenska',
    'sw-KE': 'Kiswahili',
    'sy-SY': 'ܣܘܪܝܝܐ',
    'sz-PL': 'ślōnskŏ gŏdka',
    'ta-IN': 'தமிழ்',
    'te-IN': 'తెలుగు',
    'tg-TJ': 'Тоҷикӣ',
    'th-TH': 'ภาษาไทย',
    'tl-PH': 'Filipino',
    tr: 'Türkçe',
    'tr-TR': 'Türkçe',
    'tt-RU': 'Татарча',
    'tz-MA': 'ⵜⴰⵎⴰⵣⵉⵖⵜ',
    'uk-UA': 'Українська',
    'ur-PK': 'اردو',
    'uz-UZ': "O'zbek",
    'vi-VN': 'Tiếng Việt',
    zh: '中文',
    'zh-CN': '简体中文',
    'zh-HK': '繁體中文（香港）',
    'zh-MO': '繁體中文（澳門）',
    'zh-TW': '繁體中文（台灣）',
    'zz-TR': 'Zaza',
});
