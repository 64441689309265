// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    name: z.string().min(1),
    indicator: z.string().min(1),
    isDefault: z.boolean().optional(),
    accessTokenTtl: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    name: z.string().min(1),
    indicator: z.string().min(1),
    isDefault: z.boolean(),
    accessTokenTtl: z.number(),
});
export const Resources = Object.freeze({
    table: 'resources',
    tableSingular: 'resource',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        name: 'name',
        indicator: 'indicator',
        isDefault: 'is_default',
        accessTokenTtl: 'access_token_ttl',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'name',
        'indicator',
        'isDefault',
        'accessTokenTtl',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
