// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    applicationId: z.string().min(1).max(21),
    name: z.string().min(1).max(256),
    value: z.string().min(1).max(64),
    createdAt: z.number().optional(),
    expiresAt: z.number().nullable().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    applicationId: z.string().min(1).max(21),
    name: z.string().min(1).max(256),
    value: z.string().min(1).max(64),
    createdAt: z.number(),
    expiresAt: z.number().nullable(),
});
export const ApplicationSecrets = Object.freeze({
    table: 'application_secrets',
    tableSingular: 'application_secret',
    fields: {
        tenantId: 'tenant_id',
        applicationId: 'application_id',
        name: 'name',
        value: 'value',
        createdAt: 'created_at',
        expiresAt: 'expires_at',
    },
    fieldKeys: [
        'tenantId',
        'applicationId',
        'name',
        'value',
        'createdAt',
        'expiresAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
