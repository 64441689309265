// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    organizationId: z.string().min(1).max(21),
    userId: z.string().min(1).max(21),
});
const guard = z.object({
    tenantId: z.string().max(21),
    organizationId: z.string().min(1).max(21),
    userId: z.string().min(1).max(21),
});
export const OrganizationUserRelations = Object.freeze({
    table: 'organization_user_relations',
    tableSingular: 'organization_user_relation',
    fields: {
        tenantId: 'tenant_id',
        organizationId: 'organization_id',
        userId: 'user_id',
    },
    fieldKeys: [
        'tenantId',
        'organizationId',
        'userId',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
