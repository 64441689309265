// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    applicationId: z.string().min(1).max(21),
    roleId: z.string().min(1).max(21),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    applicationId: z.string().min(1).max(21),
    roleId: z.string().min(1).max(21),
});
export const ApplicationsRoles = Object.freeze({
    table: 'applications_roles',
    tableSingular: 'applications_role',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        applicationId: 'application_id',
        roleId: 'role_id',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'applicationId',
        'roleId',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
